.tuareg__navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.tuareg__navbar-menu svg {
  cursor: pointer;
}

.tuareg__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  background-color: white;
  text-align: end;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.tuareg__navbar-menu_container-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@media screen and (max-width: 1050px) {
  .tuareg__navbar-links_container {
    display: none;
  }
  .tuareg__navbar-menu {
    display: flex;
  }
}

.user-menu-container {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 150%;
  right: 0; /* Cambiamos 'left' por 'right' */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 200px; /* Ajusta la anchura según tus necesidades */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.option {
  cursor: pointer;
  display: block;
  padding: 8px 12px;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s ease;
}

.option:hover {
  background-color: #f5f5f5 !important;
  text-decoration: none;
  /* otros estilos adicionales para el efecto hover */
}

.option:last-child {
  margin-top: 10px;
}

.option:last-child:hover {
  background-color: transparent;
}

.option a {
  color: #333;
  text-decoration: none;
}

.option a:hover {
  text-decoration: underline;
}
