.header-content {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-gap: 10px; /* Espacio entre las columnas, ajusta según tus necesidades */
  align-items: start;
}
@media screen and (max-width: 1023px) {
  .header-content {
    grid-template-columns: 1fr;
    margin-bottom: 6rem;
  }
  .order-summary-block {
    margin: auto;
    order: -1;
    margin-top: 4rem;
  }
  .title-data-clt {
    text-align: center;
  }
}
