/* block sumary */
.order-summary-block {
  // background-color: #f9f9f9;
  padding: 0 15px;
  line-height: 1.4;
  border: 1px solid #dce1e4;
  border-radius: 10px;
  // box-shadow: 0 1.5px 2.5px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  text-align: center;
  &__title {
    padding: 10px 0;
    border-bottom: 1px solid #94989a;
    font-weight: 700;
  }
  &__li {
    border: 0 !important;
  }
  &__total {
    font-size: 18px;
    font-weight: 700;
  }

  &__content {
    padding: 20px 0;
    font-size: 0.8em;
    p {
      margin: 10px 0;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        padding: 8px 0;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
