.confirmation-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.confirmation-container {
  max-width: 600px;
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.confirmation-title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
}

.confirmation-message {
  font-size: 18px;
  text-align: center;
  margin-bottom: 16px;
}

.confirmation-details {
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
}

.back-container {
  display: flex;
  justify-content: center;
}

.back-link {
  font-size: 16px;
  color: #555555;
  text-decoration: none;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: #333333;
}
