.carousel {
  display: flex;
  // align-items: center;
  // justify-content: center;

  margin: 0 auto;

  .carousel__item {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;

    .product-image {
      // max-width: 100%;
      height: 250px;
      width: 250px;
      border-radius: 8px;
      // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      object-fit: scale-down;
    }

    .product-price {
      margin-top: 10px;
      font-size: 1.2rem;
      font-weight: 600;
      color: #333;
    }

    .product-title {
      margin-top: 5px;
      font-size: 1rem;
      font-weight: 500;
      color: #444;
    }

    // Responsive adjustments for smaller screens
  }
}

.section-gallery {
  max-width: 1536px;
  position: relative;
}

.section-gallery::before,
.section-gallery::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ccc; /* Color de la línea separadora */
}

.section-gallery::after {
  bottom: 10px; /* Ajusta la posición vertical de la línea inferior */
}
.react-multiple-carousel__arrow {
  z-index: 0 !important;
}
