.top-bar {
  background-color: #333;
  color: #fff;
  padding: 10px;

  &__row {
    max-width: 90dvw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
  &__contact-info-item {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    overflow-x: hidden;
  }
  &__text-wrapp {
    max-width: 400px;
    white-space: nowrap; /* Para que el texto no se divida en varias líneas */
    animation: scroll 10s linear infinite;
  }
  &__right-element {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
  }
  &__contact-info {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  &__offer {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}

@media (max-width: 1080px) {
  .top-bar {
    display: none;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-150%);
  }
}
