.popup {
  position: fixed;

  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: #333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: white;
}

.popup-buttons {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

.popup-button {
  margin: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #484848;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-button svg {
  margin-right: 5px;
}

.popup-button:hover {
  background-color: #fbfbfb;
  color: black;
}
