.product-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .main-image {
    width: 100%;
    height: auto;

    // overflow: hidden;
    position: relative;

    img {
      width: 500px;
      height: 400px;
      object-fit: scale-down;
      transition: transform 0.3s ease-in-out;
    }
  }
  .arrow-buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    width: 100%;

    .arrow-button {
      font-size: 24px;
      color: white;
      border: none;
      cursor: pointer;
      // padding: 7px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      width: 45px;
      height: 45px;
    }

    .arrow-left {
      position: absolute;
      left: 30px;
      transform: translateX(-100%);
    }

    .arrow-right {
      position: absolute;
      right: 30px;
      transform: translateX(100%);
    }
  }

  .hover-images {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;

    img {
      width: 100px;
      height: 100px;
      cursor: pointer;
      object-fit: contain;
      border-radius: 4px;
      border: 2px solid rgb(209, 207, 207);
      transition: border-color 0.3s ease-in-out;
    }
    img.active {
      border-color: black;
    }
  }
  .arrow-buttons {
    display: none;
  }
  @media (max-width: 768px) {
    /* Oculta las imágenes en pantallas pequeñas */
    .hover-images-img {
      display: none;
    }
    .dot {
      width: 8px;
      height: 8px;
      background-color: gray;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
    }
    .dot.active {
      background-color: black;
    }
    .arrow-buttons {
      display: block;
    }
  }
  // @media (max-width: 490px) {
  //   .main-image {
  //     // width: auto;
  //     // height: auto;

  //     img {
  //       max-width: 100%;

  //       transition: transform 0.3s ease-in-out;
  //     }
  //   }
  // }
  /* ... Otras reglas de estilo ... */
}
