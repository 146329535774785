.change-password-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.password-form label {
  font-size: 16px;
  display: block;
  margin-bottom: 6px;
}

.password-form input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 16px;
  transition: border-color 0.3s ease-in-out;
}

.password-form input:focus {
  outline: none;
  border-color: #007bff;
}

.error-message {
  color: #ff0000;
  font-size: 14px;
  margin: 10px auto;
}

.success-message {
  color: white;
  padding: 15px 12px;
  background-color: green;
  margin: 10px auto;
  font-weight: bold;
  font-size: 18px;
  border: 15px;
  border-radius: 8px;
  text-align: center;
}
