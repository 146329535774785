.cart-page {
  padding: 20px;
  max-width: 1124px;
  margin: auto;
  margin-bottom: 88px;
  position: relative;
}

.cart-page h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.cart-list {
  list-style: none;
  padding: 0;
}

.cart-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.item-info {
  flex: 1;
}

.item-info h3 {
  font-size: 18px;
  margin: 0;
}

.item-info p {
  font-size: 14px;
  margin: 0;
  color: #777;
}

.item-quantity {
  display: flex;
  align-items: center;
}

.quantity-btn {
  border: none;
  background-color: transparent;
  color: #555;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  padding: 3px 8px;
}

.quantity-btn:hover {
  color: #333;
}

.remove-btn {
  border: none;
  background-color: transparent;
  color: #f00;
  font-size: 14px;
  cursor: pointer;
}

.remove-btn:hover {
  color: #c00;
}
.frete-value {
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #ccc;
  padding: 25px 0;
  border-bottom: 1px solid #ccc;
}

.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  justify-content: flex-end;
}

.cart-summary p {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.checkout-btn {
  border: none;
  /* background-color: #0a1352; */
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

/* Frete options*/
.frete-options {
  margin-top: 15px;
}

/* Estilos para el contenedor de las opciones */
.radio-option {
  margin-bottom: 10px;
}

/* Estilos para el input de radio */
.radio-option input[type="radio"] {
  margin-right: 10px; /* Espacio entre el radio button y el label */
}

/* Estilos para el label del radio button */
.radio-option label {
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  font-size: 16px;
}

/* Estilos para el texto que muestra el valor del frete */
.radio-option label span {
  font-weight: bold;
}

/* Estilos para resaltar la opción seleccionada */
.radio-option input[type="radio"]:checked + label {
  color: #606060; /* Color azul para resaltar la opción seleccionada */
}

input[type="radio"] {
  accent-color: #232323;
}

@media screen and (max-width: 1023px) {
  .header-content {
    grid-template-columns: 1fr;
  }
  .order-summary-block {
    margin: auto;
  }
  .title-data-clt {
    text-align: center;
  }
}
