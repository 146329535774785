::selection {
  background: #333;
  color: #fff;
}
.wrapper {
  overflow: hidden;
  max-width: 390px;
  background: rgba(34, 34, 34, 0.92);
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1000;
}
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Z-index inferior al del LoginPopUp */
}
.wrapper .title-text {
  display: flex;
  width: 200%;
}
.wrapper .title {
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin: 0;
  background: #3e3e3e;
  padding: 10px;
  color: white;
  border-radius: 15px;
}
.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 5px;
}
.slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}
.slide-controls label.signup {
  color: #000;
}
.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 5px;
  background: -webkit-linear-gradient(left, #7a7a7a, #333);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.login-popup-radio {
  display: none;
}
#signup:checked ~ .slider-tab {
  left: 50%;
}
#signup:checked ~ label.signup {
  cursor: default;
  user-select: none;
  color: #333;
  background-color: #fff;
}
#signup:checked ~ label.login {
  color: #fff;
}
#login:checked ~ label.signup {
  color: #fff;
}
#login:checked ~ label.login {
  color: #fff;
  cursor: default;
  user-select: none;
  color: #333;
  background-color: #fff;
}
.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}
.form-container .form-inner {
  display: flex;
  width: 200%;
}
.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
}
.form-inner form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}
.form-inner form .field input:focus {
  border-color: #a2a2a3;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner form .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}
form .field input:focus::placeholder {
  color: #b3b3b3;
}
.form-inner form .pass-link {
  margin-top: 20px;
}
.form-inner form .signup-link {
  text-align: center;
  margin-top: 30px;
  color: #b4b4b4;
  cursor: pointer;
}
.form-inner form .pass-link a,
.form-inner form .signup-link a {
  color: #eeecec;
  text-decoration: none;
}
.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
}
form .btn {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
form .btn .btn-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right, #727375, #333, #e5e7eb, #333);
  border-radius: 5px;
  transition: all 0.4s ease;
}
form .btn:hover .btn-layer {
  left: 0;
}
.login-submit-btn {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none !important;
  color: #fff;
  padding-left: 0 !important;
  border-radius: 5px !important;
  font-size: 20px !important;
  font-weight: 500;
  cursor: pointer;
}

// social media login

.striped {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &-before {
    margin: 1rem 0;
  }

  &-line {
    flex: auto;
    flex-basis: auto;
    border: none;
    outline: none;
    height: 2px;
    background: #a3a3a3;
  }

  &-text {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: inherit;
    color: white;
    margin: 0 1rem;
  }
}

.method {
  &-control {
    margin-bottom: 1rem;
  }

  &-action {
    font-family: inherit;
    font-size: 0.95rem;
    font-weight: 500;
    line-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    height: auto;
    padding: 0.35rem 1.25rem;
    outline: none;
    border: 2px solid #dadce0;
    border-radius: 2rem;
    color: #121212;
    background: #ffffff;
    text-transform: capitalize;
    text-rendering: optimizeLegibility;
    transition: all 0.35s ease;
    cursor: pointer;

    &:hover {
      background: #ebebeb;
    }
  }
}

.google-icon {
  color: #db4a39; /* Color de Google */
  font-size: 22.5px;
}

.facebook-icon {
  color: #1877f2; /* Color de Facebook */
  font-size: 22.5px;
}

.apple-icon {
  color: #000000; /* Color de Apple */
  font-size: 22.5px;
}
