// CorreiosSection.scss

.correios-section-container {
  display: flex;
  align-items: center;
  padding: 4rem;
  margin-bottom: 8rem;
}

.correios-image {
  flex: 40%;
}
.correios-image img {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
.correios-content {
  flex: 60%;
}

.correios-content-h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.advantages-list {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
  margin-top: 4rem;
}
.advantage-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.advantage-item p {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .correios-image img {
    margin-top: 3rem;
  }
  .correios-section-container {
    padding: 0rem;
    width: 85dvw;
  }
  .correios-section-container {
    flex-direction: column;
  }
}
