.perfil-header {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  min-height: 75vh;
}
.user-icon {
  margin-right: 5px; /* Ajusta el margen según tus necesidades */
  vertical-align: middle;
}
.menu-container {
  display: flex;
  background-color: #f2f2f2; /* Cambia el color de fondo según tu diseño */
  padding: 10px;
  border-radius: 20px;
}

.menu {
  display: flex;
  align-items: center;
}

.menu-item {
  margin-right: 10px;
  color: #888; /* Cambia el color de texto según tu diseño */
}

.menu-item.active {
  cursor: pointer;
  font-weight: bold;
  color: #333; /* Cambia el color de texto activo según tu diseño */
}

.separator {
  margin: 0 10px;
  color: #888; /* Cambia el color del separador según tu diseño */
}

.title {
  margin-top: 40px; /* Ajusta el margen según tus necesidades */
  margin-bottom: 80px;
}
// detalles de compra
.perfil-header {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  min-height: 75vh;
}

.table-container {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
}

.table th {
  background-color: #f2f2f2;
  color: black;
}

@media (max-width: 500px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }

  .table tr {
    margin-bottom: 47px;
    border-bottom: 2px solid #7f7f7f;
    border-top: 2px solid #7f7f7f;
  }

  .table td {
    padding-left: 50%;
    text-align: left;
    position: relative;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
}
// NoPurchaseMessage css
.no-result-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
}

.no-result-icon {
  font-size: 48px;
  color: #ccc; /* Color del ícono */
  margin-bottom: 10px;
}

.cart-icon {
  vertical-align: middle;
}

.no-result-text p {
  padding: 10px 5px;
  color: #555; /* Color del texto */
  margin-top: 10px;
  font-size: 19px;
}
