.product-availability {
  margin-top: 65px;
  display: flex;
  justify-content: center;
}
.container-img-product {
  justify-content: space-between;
}
.external-markets {
  border: 1px solid #e7e5e5;
  padding: 10px;
  position: relative;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 1.5px;
}

.external-markets:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ccc;
}

.external-markets p {
  position: absolute;
  top: -15px;
  left: 10px;
  background-color: #fff;
  padding: 0 5px;
  margin: 0;
}
.environment {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 480px) {
  .environment {
    margin-top: 20px;
  }
}

@media (max-width: 1028px) {
  .container-img-product {
    justify-content: center;

    flex-wrap: wrap;
  }
}

.out-of-stock-message {
  display: flex;
  align-items: center;
  color: #ff5733;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
}

.out-of-stock-message .icon {
  margin-right: 10px;
}
