.sign-in {
  width: 440px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  // & .title {
  //   margin: 10px 0;
  // }
  & .buttons {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .sign-in {
    // justify-content: center;
    // align-items: center;
    width: auto;
  }
  .sign-in .buttons {
    justify-content: space-around;
  }
}

@media (max-width: 899px) {
  .sign-in {
    width: auto;
  }
}

.redefinir-senha-span {
  display: block;
  font-size: 18px;
  color: #000;
  margin-top: 35px;
}
