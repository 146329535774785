@media (max-width: 800px) {
  .footer__row {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
  .ele-1 {
    order: 1;
  }
  .ele-3 {
    order: 4;
  }
}

@media (max-width: 450px) {
  .payment-wrapper {
    display: block;
    text-align: center;
  }
  .icon-wrapper {
    justify-content: center;
    margin: 1rem 0 2rem;
  }
}
