.form-title-sin-in-up {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.sign-in-and-sign-up {
  max-width: 1000px;
  min-height: 80vh;
  display: flex;
  justify-content: space-between;
  margin: 30px auto;
  flex-wrap: wrap;
  background-color: #e9e9e9;
  border-radius: 0.7rem;
  box-shadow: 0 0.9rem 1.7rem rgba(0, 0, 0, 0.25),
    0 0.7rem 0.7rem rgba(0, 0, 0, 0.22);
  padding: 50px;
  position: relative;
  transition: transform 0.6s ease-in-out; /* Agrega esta línea */
  overflow: hidden;
}
.title-sign-in-up {
  font-size: 20px;
  padding: 15px;
  margin: 15px 0;
}
.overlay {
  background: white;
  width: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  transition: transform 0.6s ease-in-out; /* Agrega esta línea */
  top: 0;
  z-index: 10;
}
.overlay.right {
  left: 0;
}
.overlay.left {
  transform: translateX(100%); /* Agrega esta línea */
  left: 0;

  // transform: translateX(-100%); /* Agrega esta línea */
}
.SignIn,
.SignUp {
  transition: transform 0.6s ease-in-out; /* Agrega esta línea */
}

.SignIn.idle {
  transform: translateX(200%); /* Agrega esta línea */
  // animation: show 0.6s;
  z-index: -1;
}

.SignUp.idle {
  transform: translateX(-200%); /* Agrega esta línea */
  // animation: show 0.6s;
  z-index: -1;
}
.SignIn.active {
  z-index: 2;
  animation: show 0.6s;
}

.SignUp.active {
  z-index: 2;
  animation: show 0.6s;
}

.logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

@media (max-width: 1023px) {
  .sign-in-and-sign-up {
    padding: 20px;
  }
}
@media (max-width: 899px) {
  .sign-in-and-sign-up {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    gap: 50px;
  }
  .title-sign-in-up {
    margin-top: 80px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: bold;
  }
}
@media (max-width: 930px) {
  .overlay {
    display: none;
  }
  .SignIn.idle {
    transform: translateX(0%); /* Agrega esta línea */
    // animation: show 0.6s;
    z-index: 1;
  }

  .SignUp.idle {
    transform: translateX(0%); /* Agrega esta línea */
    // animation: show 0.6s;
    z-index: 1;
  }
  .sign-in-and-sign-up {
    box-shadow: none;
  }
}
@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}
