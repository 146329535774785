.floating-whatsapp-icon-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366; /* Color de fondo del icono de WhatsApp */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s; /* Agrega una transición para mejorar la apariencia */
  z-index: 1000;
}

.floating-whatsapp-icon-container:hover {
  background-color: #128c7e; /* Color de fondo al pasar el mouse por encima */
}
