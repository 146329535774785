/* Estilos generales */
// .form-container {
//   //   max-width: 400px;
//   //   margin: 0 auto;
// }

.form-label {
  display: block;
  font-weight: bold;
  width: 350px;
}

.form-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-select {
  width: 100%;
  margin-bottom: 16px;
}

.form-button {
  margin-bottom: 50px;
}

/* Estilos específicos para el formulario */
.form-section {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;

  row-gap: 15px;
}

.form-section-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.form-address-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.form-address-inputs input {
  flex: 1;
}

.area-button {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 1023px) {
  .form-section {
    justify-content: center;
  }
  .area-button {
    justify-content: center;
  }
}
