.payment-method {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .payment-option {
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;

    .payment-icon {
      font-size: 24px;
      color: #a3a3a3;
    }

    .payment-details {
      flex: 1;

      h3 {
        font-size: 18px;
        margin-bottom: 5px;
      }

      p {
        font-size: 14px;
        color: #555;
        a {
          color: #a3a3a3;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
