.custom-button {
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
  &.google-sign-in {
    background-color: #4285f4;
    color: white;
    border: 1px solid transparent;

    &:hover {
      background-color: darken($color: #4285f4, $amount: 15);
      border: 1px solid black;
    }
  }
  &.inverted {
    background-color: white;
    color: black;
    border: 1px solid black;
    &:hover {
      background-color: black;
      color: white;
      border: 1px solid white;
    }
  }
}

@media (max-width: 899px) {
  .custom-button {
    font-size: 12px;
    padding: 0 22px;
  }
}

@media (max-width: 480px) {
  .custom-button {
    min-width: auto;
    // font-size: 10px;
    // padding: 0 20px;
  }
}
