// ResetPasswordComponent styles
.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  background-color: #f9f9f9;
  box-shadow: 0px 3px 1.5px rgba(32, 32, 32, 0.2);
  margin-top: 32px;
  border-radius: 8px;
}

.reset-password-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  gap: 15px;
}

.email-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.email-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    border-color: black; /* Cambiar el color del borde en el estado enfocado */
    outline: none; /* Eliminar el contorno predeterminado en el estado enfocado */
    box-shadow: 0 0 5px rgba(67, 68, 68, 0.5); /* Agregar una sombra suave en el estado enfocado */
  }
}

.reset-button {
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}
