.react-tabs__tab {
  border-radius: 10px 10px 0px 0px;
}
.react-tabs__tab-list {
  //   border-bottom: none !important;
  font-size: 1.1rem;
}
input {
  outline: none !important;
  //   box-shadow: 0 0 10px #719ece;
}
.selected-tab,
.selected-tab:focus {
  color: white;
  background-color: rgb(15 23 42);

  //   border-bottom: 5px solid red;
}
.shipping-col1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.shipping-container-input {
  padding: 1rem 2rem;
}
.shipping-input {
  padding: 0 0.3rem;
  color: black;
  width: 100%;
}
.shipping-title {
  font-size: 1.9rem;
}
/* Estilo para la sección de detalles técnicos */
/* Estilo para la sección de detalles técnicos */
.technical-details {
  margin-top: 24px;
  padding: 16px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.technical-details h3 {
  margin: 0 0 16px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-decoration: underline;
}

.technical-details-p {
  margin: 8px 0;
  font-size: 15px;
  line-height: 1.4;
}

.technical-details-p:last-child {
  margin-bottom: 0;
}

@media (max-width: 570px) {
  .shipping-col1 {
    flex-direction: column;
    gap: 1.5rem;
  }
}

/* En TuComponente.css */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.close-icon {
  font-size: 2rem; /* Ajusta el tamaño según tus necesidades */
  color: #333; /* Cambia el color según tus preferencias */
}

/* Estilos para el modal */
.custom-modal {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Estilos para el botón de cierre */
.close-button {
  background: none;
  border: none;
  cursor: pointer;
}
// .modal-content {
//   // text-align: center;
// }

.modal-purchase-detail {
  // font-size: 0.9rem;
  margin-bottom: 1rem;
}
.image-container-preshipping {
  width: 18rem;
  height: 9rem;
}
.modal-info {
  display: flex;
  gap: 15%;
  justify-content: center;
  align-items: center;
  // flex-direction: column;
}
.image-frete {
  width: 100%;
  height: 100%;
}

.modal-info p {
  margin: 0.5rem 0;
}
.ReactModal__Content {
  z-index: 100;
}

@media (max-width: 1400px) {
  .modal-info {
    flex-direction: column;
    row-gap: 2.5rem;
  }
  .ReactModal__Content {
    width: 80% !important;
    height: 70% !important;
  }
}
@media (max-width: 374px) {
  .shipping-container-input {
    padding: 1rem 0;
  }
}
